const ServiceData = [
       {
          id:"is1",
          icon:"fas fa-chart-pie",
          title: "Software Development",
          text:"",
          iconBackground:"#494ca2"
        },
        {
          id:"is2",
          icon:"fas fa-layer-group",
          title: "Web & Mobile Development",
          text:"",
          iconBackground:"#494ca2"
        },
        {
          id:"is5",
          icon:"fas fa-file-invoice",
          title: "Design",
          text:"",
          iconBackground:"#0f89ff"
        },
    
    ]
  export default ServiceData;