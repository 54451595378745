const SocialMediaData = [
    {   
        id: "sm1",
        platform: "twitter",
        link: "https://twitter.com/AfratexLtd",
        fa_icon: "fab fa-twitter"
    },
    {
        id: "sm2",
        platform: "linkedin",
        link: "#",
        fa_icon: "fab fa-linkedin"
    }
]
export default SocialMediaData;