import LogoBlack from "~image/logo/logo-black.png";
import LogoWhite from "~image/logo/logo-white.png";
import counDownImage from "~image/mixed/pattern-img-1.png";
import herol3Imgwoman from "~image/it-services/welcome-woman-l3.png";
import herol3Imgman from "~image/it-services/welcome-man-l3.png";
import herol3ImgShape1 from "~image/it-services/welcome-shape-l3.png";
import herol3ImgShape2 from "~image/it-services/welcome-shape-l3-2.png";
import l3serVicesShape from "~image/it-services/services-more-shape.png";
import l3featureImg from "~image/it-services/feature-l3-image.png";
import l3conTentOneImg from "~image/it-services/feature-l3-image.png";
import l3conTentTwoImg from "~image/it-services/content-l3-img-1.png";
import l3promoBg from "~image/it-services/promo-bg.png";
import l3testiMonialUserImg1 from "~image/it-services/user-circle-1.png";
import l3testiMonialUserImg2 from "~image/it-services/user-circle-2.png";
import l3testiMonialUserImg3 from "~image/it-services/user-circle-3.png";
import footer3instaImg1 from "~image/it-services/insta-1.png";
import footer3instaImg2 from "~image/it-services/insta-2.png";
import footer3instaImg3 from "~image/it-services/insta-3.png";
import footer3instaImg4 from "~image/it-services/insta-4.png";
import footer3instaImg5 from "~image/it-services/insta-5.png";
import footer3instaImg6 from "~image/it-services/insta-6.png";
import errorImage from "~image/mixed/404.png";


const Images = {
    Logo:{
        LogoBlack: LogoBlack,
        LogoWhite: LogoWhite
    },
    itServices: {
        heroImgwoman: herol3Imgwoman,
        heroImgman: herol3Imgman,
        heroImgShape1: herol3ImgShape1,
        heroImgShape2: herol3ImgShape2,
        serVicesShape: l3serVicesShape,
        featureImg: l3featureImg,
        conTentOneImg: l3conTentOneImg,
        conTentTwoImg: l3conTentTwoImg,
        promoBg: l3promoBg,
        testiMonialUserImg1: l3testiMonialUserImg1,
        testiMonialUserImg2: l3testiMonialUserImg2,
        testiMonialUserImg3: l3testiMonialUserImg3,
    },
    footerThree: {
        instaImg1: footer3instaImg1,
        instaImg2: footer3instaImg2,
        instaImg3: footer3instaImg3,
        instaImg4: footer3instaImg4,
        instaImg5: footer3instaImg5,
        instaImg6: footer3instaImg6,
    },

    errorImage: errorImage,
    CountdownBgImg: counDownImage

}

export default Images;